import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { useMemo } from 'react'

import { ListBox, ListBoxOption } from './ListBox'

interface Props {
    deviceId: string | undefined
    onChange(deviceId: string): void
    hasPermissions: boolean
    hasMicrophone: boolean
    microphones: Device[]
}

export const MicrophonesListBox = ({ deviceId, onChange, hasPermissions, hasMicrophone, microphones }: Props) => {

    const systemDefaultMicrophone = useMemo(() => microphones?.find((device) => device.deviceId === 'default'), [microphones])

    const options = useMemo(
        (): ListBoxOption[] =>
            microphones?.map((device) => {
                return {
                    value: device.deviceId,
                    displayNode: device.label,
                }
            }),
        [microphones]
    )

    const optionSelected = useMemo(() => {
        const _deviceId = deviceId ?? systemDefaultMicrophone?.deviceId
        return _deviceId ? options.find((option) => option.value === _deviceId) : undefined
    }, [deviceId, systemDefaultMicrophone, options])

    if (hasPermissions === false) {
        return (
            <div className="text-gray-500 flex">
                Microphone access required.
                <br />
                To continue, please enable microphone permissions in your device settings.
            </div>
        )
    }

    if (hasMicrophone === false) {
        return (
            <div className="text-gray-500 flex">
                <ExclamationTriangleIcon className="text-orange h-6 w-6 mr-2" />
                <div>No microphones were detected on your device</div>
            </div>
        )
    }

    return <ListBox placeholder="Select Microphone" options={options} optionSelected={optionSelected} onChange={(option) => onChange(option.value)} />
}

interface Device {
    deviceId: string
    label: string
}
