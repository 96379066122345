import { useCallback, useEffect, useRef, useState } from 'react'
import { AudioVisualizer, LiveAudioVisualizer } from 'react-audio-visualize'

import Colors from '../../colors'
import { mediaRecorder } from '../../lib/audio'

interface Props {
    className?: string
    deviceId?: string
}

export const TestMicrophoneButton = ({ deviceId }: Props) => {
    const [recorder, setRecorder] = useState<MediaRecorder | null>(null)
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const [blob, setBlob] = useState<any>(null)
    const [currentPlayedTime, setCurrentPlayedTime] = useState<number>(0)
    const audioRef = useRef<HTMLAudioElement | null>(null)
    const audioVisualizerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {        
        if (audioRef.current) {
            const audioElement = audioRef.current
            const handleTimeUpdate = () => {
                setCurrentPlayedTime(audioElement.currentTime)
            }
            audioElement.addEventListener('timeupdate', handleTimeUpdate)

            return () => {
                audioElement.removeEventListener('timeupdate', handleTimeUpdate)
            }
        }
    }, [audioRef.current?.currentTime])

    const startRecording = useCallback(async () => {
        if (!deviceId) return

        if (audioRef.current) {
            audioRef.current.pause()
            audioRef.current.currentTime = 0 // Reset the audio to the beginning
        }
        setBlob(null)

        const playFunction = (chunks: any) => {
            const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
            setBlob(blob)

            const audioUrl = URL.createObjectURL(blob)
            if (audioRef?.current) {
                audioRef.current.src = audioUrl
                audioRef.current.play().catch((e) => console.error('Autoplay failed:', e))
            }
        }

        const newRecorder = await mediaRecorder.getMediaRecorder(deviceId, playFunction)
        setRecorder(newRecorder)
        newRecorder.start()
        setIsRecording(true)
        setTimeout(() => {
            newRecorder.stop()
            setRecorder(null)
            setIsRecording(false)
        }, 5000)
    }, [deviceId])

    const stopRecording = async () => {
        recorder?.stop()
        setRecorder(null)
        setIsRecording(false)
    }

    if (!deviceId) {
        return null
    }

    return (
        <div className="flex flex-col">
            <div className="flex items-center gap-2">
                <div>
                    {isRecording ? (
                        <button onClick={stopRecording} className="w-12 h-11 flex items-center justify-center border border-gray-300 rounded-lg">
                            <span className="w-4 h-4 bg-rose-600 rounded"></span>
                        </button>
                    ) : (
                        <button onClick={startRecording} className=" border border-gray-300 rounded-lg text-sm font-semibold text-gray-800">
                            Test
                        </button>
                    )}
                </div>
                <div className="flex-1">
                    <div ref={audioVisualizerRef} className="h-11">
                        {recorder && (
                            <LiveAudioVisualizer
                                barWidth={3}
                                gap={2}
                                barColor={Colors.PRIMARY}
                                height={audioVisualizerRef.current?.offsetHeight}
                                width={audioVisualizerRef.current?.offsetWidth}
                                mediaRecorder={recorder}
                            />
                        )}
                        {blob && !isRecording && audioRef.current && (
                            <AudioVisualizer
                                blob={blob}
                                barWidth={3}
                                barPlayedColor={Colors.PRIMARY}
                                currentTime={currentPlayedTime}
                                gap={2}
                                height={audioVisualizerRef.current?.offsetHeight || 0}
                                width={audioVisualizerRef.current?.offsetWidth || 0}
                            />
                        )}
                    </div>
                    <audio ref={audioRef} controls={false} />
                </div>
            </div>
            <p className="text-xs mt-2 text-gray-500">Say: “Fluent is the best app out there!” If you don’t see any blinking indictors, select a different microphone.</p>
        </div>
    )
}
