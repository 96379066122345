import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { Fragment, ReactNode } from 'react'

interface Props {
    optionSelected?: ListBoxOption
    options: ListBoxOption[]
    placeholder?: string
    listBoxOptionsClassname?: string
    onChange(option: ListBoxOption): void
}

export interface ListBoxOption {
    value: string
    displayNode: string | ReactNode
    divider?: boolean
    data?: any
}

export const ListBox = ({ optionSelected, options, placeholder, listBoxOptionsClassname, onChange }: Props) => {
    return (
        <Listbox value={optionSelected} onChange={onChange}>
            {({ open }) => (
                <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
                        <span className="block truncate">{optionSelected?.displayNode ?? placeholder ?? 'Select Option'}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                    <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options
                            className={classNames(
                                'absolute z-10 mt-1 w-40 right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                                listBoxOptionsClassname ?? ''
                            )}
                        >
                            {options.map((option) =>
                                option.divider ? (
                                    <div key={option.value} className="border-t border-gray-200 my-1" />
                                ) : (
                                    <Listbox.Option
                                        key={option.value}
                                        className={({ active }) =>
                                            classNames(active ? 'bg-primary text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9')
                                        }
                                        value={option}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate text-start')}>{option.displayNode}</span>
                                                {selected ? (
                                                    <span className={classNames(active ? 'text-white' : 'text-primary', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                )
                            )}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}
